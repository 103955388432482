.menu-superior{
    width: 100%;
    height: 100%;
    font-family: 'Nexa B';
    font-size: 24px;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn_item{
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;

        button{
            height: 100%;
            background: transparent;
            border: none;
            color: #fff;
            font-size: 30px;
            
            :hover{
                color: #ff0;
            }
        }
    }
    
    p{
        margin: 0;
        color: #fff;
    }
    .text-libro{
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .btnCerrar{
            margin-right: 5px;
        }
    }
    
    .text-libro:hover{
        color: #ff0;
        cursor: pointer;
    }   
}