html,
body {
    margin: 0;
    padding: 0;
}

.contenido-general {
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-image: url("../../images/fondoComprension.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}
.contenedor {
    width: 858px;
    height: 595px;
    // background: #ffffff;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .login-izq {
        background-image: url("../../images/tapaComprension.png");
        width: 50%;
        height: 100%;

        display: flex;
        justify-content: center;

        .animacion-logo {
            width: 270px;
            margin-top: 5%;
        }
    }

    .login-der {
        width: 50%;
        height: 99.833%;
        // height: 100%;
        background: #ffffff;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-top: 3%;
            width: 15%;
        }

        p {
            font-size: 30px;
            font-family: 'DM Sans', sans-serif;
            color: #F7931E;
        }

        .ant-alert-success {
            margin-top: -10%;
            margin-bottom: 5%;
        }

        .register-form {
            width: 70%;

            .ant-form-item {
                margin: 0 0 12px;
            }

            .ant-form-item-login {
                margin: 0 0 24px;
            }

            .ant-btn-primary {
                width: 100%;
            }
        }
        .div-enlace-cuenta{
            width: 70%;
            display: flex;
            flex-direction: row;

            .enlace-cuenta{            
               font-weight: 600;
               color: #F7931E;
            }
        }
    }

}

//MEDIA QUERIES mobile
@media screen and (min-width:320px) and ( max-width: 360px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 699px;
                flex-direction: column;
                justify-content: center;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 174px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 190px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 586px;
                    img { 
                        display: none;
                    }
    
                    p {
                        margin-top: -40px;
                        font-size: 24px;
                    }
    
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
    
                    .register-form {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:361px) and (max-width: 400px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            position: absolute;
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 715px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 200px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 210px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 526px;
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -40px;
                        font-size: 24px;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:401px) and (max-width: 450px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            position: absolute;
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 715px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 235px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 230px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 526px;
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -40px;
                        font-size: 24px;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:451px) and (max-width: 500px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 725px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 290px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 250px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 550px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:501px) and (max-width: 550px) {
    html,
    body {
        .contenido-general{ 
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 761px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 325px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 300px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 557px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 90%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:551px) and (max-width: 600px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 80%;
                height: 800px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 281px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 330px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 550px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:601px) and (max-width: 700px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 90%;
                height: 865px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 400px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 330px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 630px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
    
                    .msg-alert{
                        width: 100%;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:701px) and (max-width: 750px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;

            .contenedor {
                margin-bottom: 30px;
                width: 80%;
                height: 835px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 400px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 330px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 550px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
    
                    .msg-alert{
                        width: 100%;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:751px) and (max-width: 800px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 80%;
                height: 890px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 400px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 330px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 550px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
    
                    .msg-alert{
                        width: 100%;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 60%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:801px) and (max-width: 856px) {
    html,
    body {
        .contenido-general{
            width: 100%;
            height: 100%;
            .contenedor {
                margin-bottom: 30px;
                width: 80%;
                height: 900px;
                flex-direction: column;
                justify-content: flex-start;
                .login-izq {
                    margin-top: 10%;
                    background-image: url("../../images/imagen_mobiles.png");
                    width: 100%;
                    height: 440px;
                    background-repeat: no-repeat;
                    background-size: 100.5%;
                    .animacion-logo {
                        width: 360px;
                        margin-top: 5%;
                    }
                }
    
                .login-der {
                    width: 100%;
                    height: 550px;
                    
                    img { 
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        font-size: 24px;
                    }
    
                    .msg-alert{
                        width: 100%;
                    }
                    .ant-alert-success {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .register-form {
                        width: 60%;
                    }
                }
            }
        }
    }
}