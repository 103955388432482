.table{
    height: fit-content;
    tbody{
        vertical-align: middle;
        .tr{
            height: 60px;
            .colorSatisfactorio{
                width: fit-content;
                color: #389e0d;
                background: #f6ffed;
                border-color: 1px solid #b7eb8f;
                border: 1px solid;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
            }

            .colorMinimo{
                width: fit-content;
                color: #fd7e14;
                background: #fffaea;
                border-color: #ffc107;
                border: 1px solid;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
            }

            .colorInsuficiente{
                width: fit-content;
                color: #d4380d;
                background: #fff2e8;
                border-color: #ffbb96;
                border: 0.1px solid;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
            }
            .colorAvanzado{
                width: fit-content;
                color: #1d39c4;
                background: #f0f5ff;
                border-color: #adc6ff;
                border: 0.1px solid;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 3px;
            }
        }
    }
}