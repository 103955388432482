@import '../../../../scss/index.scss';

.content-eva{
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "aside main main main main";

    aside{
        grid-area: aside;
        background: $background-aside2;

        display: flex;
        flex-direction: column;
        align-items: center;
        .encabezado{
            width: 80%;
            margin-top: 100px;
            // height: 30%;
            // margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            img{
                width: 152px;
            }

            .textEst{
                margin: 0;
                font-family: 'Roboto';
                font-size: 26px;
                color: $font-white;
            }

            .textPerfil{
                margin: 0;
                font-family: 'Roboto';
                font-size: 18px;
                font-weight: 400;
                color: $font-yellow;
            }
        }
        .menu{
            margin-top: 100px;
            width: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;

            .tema{
                margin: 0;
                width: 80%;
            }
            .tema_tema{
                margin: 0;
                font-family: 'Roboto';
                text-align: center;
                font-size: 26px;
                color: $font-white;
            }
            .tema_nom{
                margin: 0;
                font-family: 'Roboto';
                text-align: center;
                font-size: 24px;
                color: $font-yellow;
            }
        }

        .crono{
            width: 80%;
            margin-top: 70px;

            display: flex;
            flex-direction: column;
            align-items: center;

            .nombre{
                margin: 0;
                font-family: 'Roboto';
                text-align: center;
                font-size: 22px;
                color: #ffffff;
            }                    
        }

        .logo_editorial{
            margin-top: 100px;
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            img{
                width: 100%;
            }
        }
    }

    main{
        grid-area: main;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tituloEvaluacion{
            width: 95%;
            height: 50px;
            background: $backgroun-orange;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p{
                margin: 0;
                font-family: 'Roboto';
                font-size: 26px;
                color: $font-white;
                font-weight: 400;
            }
        }

        form{
            margin-top: 10px;
            width: 95%;

            display: flex;
            flex-direction: column;

            .bloque{
                margin-top: 50px;
                width: 100%;

                .txt_bloque{
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 700;
                }
                .img_bloque{
                    width: 80%;

                    img{
                        width: 100%;
                    }
                }

            }

            .tipo1{
                @include titlequestions;

                .respuestas{
                    display: flex;
                    flex-direction: row;
                    margin-left: 4%;
                    align-items: center;
                    width: 90%;
                    height: 40px;
                }

                .letras{
                    margin: 0;
                    font-family: cursive;
                    font-size: 20px;
                    color: $font-red;
                }

                input{
                    margin-left: 5px;
                    margin-top: 3px;
                    :hover{
                        cursor: pointer;
                    }
                }
                .respuesta{
                    margin: 0;
                    font-family: cursive;
                    margin-left: 1%;
                    font-size: 18px;
                }
            }

            .tipo2{
                @include titlequestions;
                .imgpregunta{
                    margin-top: 2%;
                    margin-bottom: 2%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    img {
                        width: 100%;
                    }
                }
                .respuestas{
                    display: flex;
                    flex-direction: row;
                    margin-left: 4%;
                    align-items: center;
                    width: 90%;
                    height: 40px;
                    .letras{
                        margin: 0;
                        font-family: cursive;
                        font-size: 20px;
                        color: $font-red;
                    }
                    input{
                        margin-left: 5px;
                        margin-top: 3px;
                        :hover{
                            cursor: pointer;
                        }
                    }
                    .respuesta{
                        margin: 0;
                        font-family: cursive;
                        margin-left: 1%;
                        font-size: 18px;
                    }
                }
            }

            .tipo3{
                @include titlequestions;
                .preconimg{
                    width: 90%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }

                .respuestas{
                    display: flex;
                    flex-direction: row;
                    margin-left: 4%;
                    align-items: center;
                    width: 40%;

                    img{
                        margin-left: 1%;
                    }
                    .letras{
                        font-family: cursive;
                        font-size: 20px;
                        color: red;
                        margin: 0;
                    }
                    input{
                        margin-left: 5px;
                        margin-top: 3px;
                        :hover{
                            cursor: pointer;
                        }
                    }    
                }           
            }

            .tipo4{
                @include titlequestions;
                img{
                    margin-left: 5%;
                }
                .preconimg{
                    margin-top: 10px;
                    .respuestas{
                        display: flex;
                        flex-direction: row;
                        margin-left: 4%;
                        align-items: center;
                        width: 90%;
                        height: 40px;
                        margin-top: 10px;
                        .letras{
                            font-family: cursive;
                            font-size: 20px;
                            color: red;
                            margin: 0;
                        }
                        input{
                            margin-left: 5px;
                            margin-top: 3px;
                            :hover{
                                cursor: pointer;
                            }
                        }
                        img{
                            margin-left: 10px;
                            height: 100%
                        }
                    }
                }
            }
            .tipo5{
                @include titlequestions;
                img{
                    margin-left: 5%;
                }

                .preconimg{
                    width: 90%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .respuestas{
                        display: flex;
                        flex-direction: row;
                        margin-left: 4%;
                        align-items: center;
                        width: 40%;
                        img{
                            margin-left: 1%;
                        }
                    }
                    .letras{
                        font-family: cursive;
                        font-size: 20px;
                        color: red;
                        margin: 0;
                    }
                    input{
                        margin-left: 5px;
                        margin-top: 3px;
                        :hover{
                            cursor: pointer;
                        }
                    }
                }
            }
            .tipo6{
                @include titlequestions;
                .preconimg{
                    margin-top: 10px;
                    .respuestas{
                        display: flex;
                        flex-direction: row;
                        margin-left: 4%;
                        align-items: center;
                        width: 90%;
                        height: 60px;
                        margin-top: 10px;
                        .letras{
                            font-family: cursive;
                            font-size: 20px;
                            color: red;
                            margin: 0;
                        }
                        input{
                            margin-left: 5px;
                            margin-top: 3px;
                            :hover{
                                cursor: pointer;
                            }
                        }
                        img{
                            margin-left: 10px;
                            height: 100%
                        }
                    }
                }
            }
            .btnEnviar{
                margin-top: 30px;
                margin-bottom: 30px;
                margin-left: 35%;
                width: 250px;
                height: 50px;
                font-size: 18px;
            }
        }
    }
}