//Cotenedor de los contenidos
.div-content {
    background: #fff;
    width: 97%;
    height: 96%;
    border-radius: 10px;  
}

//Fuentes locales
@font-face {
    font-family: 'Nexa B';
    src: local('Nexa B'), url(../fonts/Nexa_Bold.otf) format('opentype');		
};


@font-face {
	font-family: 'Nexa L';
	src: local('Nexa L'), url(../fonts/Nexa_Light.otf) format('opentype');		
}

@font-face {
	font-family: 'Crono';
	src: local('Crono'), url(../fonts/ds-digi-webfont.woff) format('woff');		
}

//Colores de la aplicacion fondos
$background-blue: #019BF5;
$background-aside: #8CC63F;
$background-main: #662D91;
$background-footer: #F7931E;
$backgroun-orange:  #FFA800;
$background-aside2: #209FFF;


//Colores de fuentes de la apliacion
$font-gray: #4D4D4D;
$font-white: #fff;
$font-hover-blue: #29ABE2;
$font-hover-orange: #F15A24;
$font-item-content: #F7931E;
$font-yellow: #FFD221;
$font-red: #f00;
$font-blue: #00f;


//Media Queries Width
$media-breackpoint-min-1: 325px;
$media-breackpoint-min-2: 360px;
$media-breackpoint-min-3: 400px;
$media-breackpoint-min-4: 450px;
$media-breackpoint-min-5: 500px;
$media-breackpoint-min-6: 550px;
$media-breackpoint-min-6: 600px;
$media-breackpoint-min-7: 700px;
$media-breackpoint-min-8: 800px;
$media-breackpoint-min-9: 850px;