@import '../../../scss/index.scss';
.menu-lateral{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .logo{
        width: 90%;
        margin-top: 5px;
        img{
            width: 100%;
        }
    }

    .logo-estudiante{
        width: 60%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;

        img{
            width: 100%;
        }

        p{
            font-family: 'Nexa B';
            font-size: 18px;
            color: $font-gray;
            margin-top: 10px;
            text-align: center;
            
        }
    }

    .content-enlaces{
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .active,
        .lista-enlaces{
            width: 90%;
            height: 60px;
            margin-top: 5px;
            margin-bottom: 5px;
    
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
    
            .link{
                height: 100%;
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
    
                .iconLink{
                    height: 50%;
                    color: $font-white;
                    font-size: 24px;                
                }
                .nav-text{
                    height: 50%;
                    font-family: 'Nexa B';
                    color: $font-gray;
                    font-size: 18px;
                    margin-left: 10px;
                }
            }
        }

        .active {
            background: $font-white;
            border-radius: 30px 0 0 30px;

            .link{
                .iconLink{
                    color: $font-hover-blue;
                }
                .nav-text{
                    color: $font-hover-orange;
                }
            }
        }
    }

    
}

.menu-lateral-movil{
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: margin-left 1s;
    .logo{
        width: 100%;
        margin-top: 5px;
        img{
            width: 100%;
        }
    }
    .logo-estudiante{
        width: 90%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        img{
            width: 100%;
        }
        p{
            display: none; 
        }
    }

    .content-enlaces{
        width: 100%;
        margin-top: 20px;        
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .active,
        .lista-enlaces{
            width: 100%;
            height: 60px;
            margin-top: 5px;
            margin-bottom: 5px;
    
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
    
            .link{
                height: 100%;
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;    
                .iconLink{
                    height: 50%;
                    color: $font-white;
                    font-size: 24px;                
                }
                .nav-text{
                    display: none;
                }
            }
        }

        .active {
            background: $font-white;
            border-radius: 30px 0 0 30px;

            .link{
                .iconLink{
                    color: $font-hover-blue;
                }
                .nav-text{
                    color: $font-hover-orange;
                }
            }
        }
    }
}