@import '../scss/index.scss';
.content-layout{
    display: grid;
    min-height: 100vh;
    // grid-template-columns: minmax(80px, 250px) 1fr 1fr;
    grid-template-columns: minmax(70px, max-content) 1fr 1fr;
    grid-template-rows: 70px 1fr 50px;
    grid-template-areas: "sidebar       header      header"
                         "sidebar       contenido   contenido"
                         "sidebar       footer      footer"                         
    ;

    header {
        background: $background-blue;
        grid-area: header;
    }

    aside{
        background: $background-aside;
        grid-area: sidebar;

        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    main{
        background: $background-main;
        grid-area: contenido;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer{
        background: $background-footer;
        grid-area: footer;        
    }
}

@media screen and (max-width:780px){
    .content-layout{
        grid-template-columns: 70px 1fr 1fr;
        grid-template-rows: 70px 1fr 50px;
        grid-template-areas: "sidebar       header      header"
                            "sidebar       contenido   contenido"
                            "sidebar       footer      footer"                         
        ;
    }
}